import { defineStore } from 'pinia'
import { useSettingsStore } from './settingsStore'
import OpenAI from 'openai'

// IndexedDB setup
const DB_NAME = 'audioStorage'
const DB_VERSION = 1
const STORE_NAME = 'audioFiles'

async function openDB() {
	return new Promise((resolve, reject) => {
		const request = indexedDB.open(DB_NAME, DB_VERSION)
		
		request.onerror = () => reject(request.error)
		request.onsuccess = () => resolve(request.result)
		
		request.onupgradeneeded = (event) => {
			const db = event.target.result
			if (!db.objectStoreNames.contains(STORE_NAME)) {
				db.createObjectStore(STORE_NAME)
			}
		}
	})
}

async function storeAudio(id, blob) {
	if (!(blob instanceof Blob)) {
		throw new Error('Invalid data: not a Blob')
	}

	// Allow both audio and image MIME types
	if (!blob.type.startsWith('audio/') && !blob.type.startsWith('image/')) {
		throw new Error('Invalid data: not an audio or image MIME type')
	}

	const db = await openDB()
	return new Promise((resolve, reject) => {
		const transaction = db.transaction(STORE_NAME, 'readwrite')
		const store = transaction.objectStore(STORE_NAME)
		
		// Store both the blob and its type
		const data = {
			blob: blob,
			type: blob.type,
			timestamp: Date.now()
		}
		
		const request = store.put(data, id)
		
		request.onerror = () => reject(request.error)
		request.onsuccess = () => resolve()
	})
}

async function getAudio(id) {
	console.log('Getting audio from IndexedDB:', id)
	const db = await openDB()
	return new Promise((resolve, reject) => {
		const transaction = db.transaction(STORE_NAME, 'readonly')
		const store = transaction.objectStore(STORE_NAME)
		const request = store.get(id)
		
		request.onerror = () => {
			console.error('Error getting audio from IndexedDB:', request.error)
			reject(request.error)
		}
		request.onsuccess = () => {
			const data = request.result
			console.log('Got data from IndexedDB:', {
				id,
				hasData: !!data,
				type: data ? (data.type || (data instanceof Blob ? data.type : 'unknown')) : 'no data',
				isBlob: data instanceof Blob,
				hasBlob: data && data.blob ? 'yes' : 'no'
			})
			
			if (!data) {
				console.warn('No data found for id:', id)
				resolve(null)
				return
			}

			try {
				// If it's an old format blob, ensure it has the correct MIME type
				if (data instanceof Blob) {
					// Determine type based on ID prefix
					const defaultType = id.startsWith('image_') ? 'image/png' : 'audio/mpeg'
					const type = data.type || defaultType
					
					// Create a new blob with the correct type
					const newBlob = new Blob([data], { type })
					console.log('Created new blob from old format:', {
						id,
						type: newBlob.type,
						size: newBlob.size
					})
					resolve(newBlob)
					return
				}

				// For new format with explicit blob and type
				if (data.blob) {
					const type = data.type || (id.startsWith('image_') ? 'image/png' : 'audio/mpeg')
					// Handle both Blob and ArrayBuffer cases
					const blobData = data.blob instanceof Blob ? 
						data.blob : 
						new Uint8Array(data.blob)
					const newBlob = new Blob([blobData], { type })
					console.log('Created new blob from new format:', {
						id,
						type: newBlob.type,
						size: newBlob.size
					})
					resolve(newBlob)
					return
				}

				console.error('Invalid data format:', data)
				resolve(null)
			} catch (error) {
				console.error('Error processing data:', error)
				resolve(null)
			}
		}
	})
}

async function deleteAudio(id) {
	const db = await openDB()
	return new Promise((resolve, reject) => {
		const transaction = db.transaction(STORE_NAME, 'readwrite')
		const store = transaction.objectStore(STORE_NAME)
		const request = store.delete(id)
		
		request.onerror = () => reject(request.error)
		request.onsuccess = () => resolve()
	})
}

export const useSpaceRepetitionStore = defineStore('spaceRepetition', {
	state: () => {
		// Load initial state from localStorage
		const savedState = localStorage.getItem('spaceRepetitionStore')
		const defaultState = {
			cards: [],
			stories: [],
			currentLevel: 1,
			experiencePoints: 0,
			dailyGoal: 10,
			completedToday: 0,
			lastReviewDate: null,
			audioUrls: {}
		}

		if (savedState) {
			const parsed = JSON.parse(savedState)
			// Convert date strings back to Date objects
			if (parsed.lastReviewDate) parsed.lastReviewDate = new Date(parsed.lastReviewDate)
			if (parsed.cards) {
				parsed.cards = parsed.cards.map(card => ({
					...card,
					nextReview: new Date(card.nextReview),
					language: card.language || navigator.language.split('-')[0] || 'en'
				}))
			}
			if (parsed.stories) {
				parsed.stories = parsed.stories.map(story => ({
					...story,
					createdAt: new Date(story.createdAt),
					language: story.language || navigator.language.split('-')[0] || 'en'
				}))
			}
			return parsed
		}

		return defaultState
	},

	actions: {
		saveToLocalStorage() {
			localStorage.setItem('spaceRepetitionStore', JSON.stringify(this.$state))
		},

		detectLanguage(text) {
			// First try to use the browser's language
			const browserLang = navigator.language.split('-')[0]
			
			// Simple language detection based on common words
			const langPatterns = {
				en: /\b(the|is|are|in|on|at|he|she|they)\b/i,
				de: /\b(der|die|das|ist|sind|in|auf|er|sie|sie)\b/i,
				es: /\b(el|la|los|las|es|son|en|él|ella|ellos)\b/i,
				fr: /\b(le|la|les|est|sont|dans|sur|il|elle|ils)\b/i
			}

			// Check if text matches any language pattern
			for (const [lang, pattern] of Object.entries(langPatterns)) {
				if (pattern.test(text)) {
					return lang
				}
			}

			// Default to browser language or English
			return browserLang || 'en'
		},

		async generateStoryAndCards(topic, progressCallback = () => {}) {
			const settingsStore = useSettingsStore()
			if (!settingsStore.openAIKey) {
				throw new Error('OpenAI API key not set. Please add your API key in Settings.')
			}

			const openai = new OpenAI({
				apiKey: settingsStore.openAIKey,
				dangerouslyAllowBrowser: true
			})

			const browserLanguage = navigator.language.split('-')[0] || 'en'

			// First detect language from the topic and browser language
			const languageResponse = await openai.chat.completions.create({
				model: "gpt-4o",
				messages: [{
					role: "system",
					content: "You are a language detector. Analyze if the given topic suggests a specific language (e.g., German words suggest German content). Return only the ISO language code (en, de, es, fr) that best matches. If no language is clearly suggested, return the provided browser language."
				}, {
					role: "user",
					content: `Topic: ${topic}\nBrowser language: ${browserLanguage}`
				}],
				max_tokens: 2,
				temperature: 0
			})

			const language = languageResponse.choices[0].message.content.trim().toLowerCase() || browserLanguage

			// Then check if the topic is appropriate for young children
			const safetyResponse = await openai.chat.completions.create({
				model: "gpt-4o",
				messages: [{
					role: "system",
					content: `You are a strict child safety expert evaluating topics for very young children aged 3-8.
Your ONLY task is to determine if a topic is safe and appropriate.

IMMEDIATE AUTOMATIC REJECTIONS (respond with UNSAFE):
1. Any form of violence, conflict, or weapons (including historical)
2. Death, injury, or physical harm
3. War, battles, or military topics
4. Crime or criminal activities
5. Adult themes or relationships
6. Religious or ideological topics
7. Natural disasters or catastrophes
8. Medical procedures or serious illness

REQUIREMENTS FOR SAFE TOPICS:
1. Must be purely educational and factual
2. Must be age-appropriate (3-8 years)
3. Must have clear learning value
4. Must be positive and non-threatening
5. Must be concrete rather than abstract



Respond ONLY with either:
- "SAFE: [brief reason why it's appropriate for ages 3-8]"
- "UNSAFE: [brief explanation why it's not appropriate]"

When in ANY doubt, respond with UNSAFE.`
				}, {
					role: "user",
					content: `Evaluate this topic for young children: ${topic}`
				}],
				max_tokens: 100,
				temperature: 0
			})

			const safetyCheck = safetyResponse.choices[0].message.content.trim()
			if (!safetyCheck.startsWith('SAFE:')) {
				const errorMessageEn = `I apologize, but I cannot generate content about "${topic}". ${safetyCheck.replace('UNSAFE:', '')} Please try a different topic that's more appropriate for young children aged 3-8, such as friendly animals, nature, or simple science topics.`
				
				// Generate audio for the error message in the detected language
				try {
					progressCallback({ step: 'Generating response...', progress: 10 })

					// Translate the error message to the browser language
					const translationResponse = await openai.chat.completions.create({
						model: "gpt-4o",
						messages: [{
							role: "system",
							content: "You are a translator. Translate the given text naturally into the target language. You are talking to a child. Return only the translation, nothing else."
						}, {
							role: "user",
							content: `Translate to ${language}: ${errorMessageEn}`
						}],
						max_tokens: 200,
						temperature: 0.3
					})

					const translatedErrorMessage = translationResponse.choices[0].message.content.trim()
					
					const audioId = await this.textToSpeech(translatedErrorMessage, language)
					if (audioId) {
						progressCallback({ step: 'Playing response...', progress: 20 })
						const audioUrl = await this.getAudioUrl(audioId)
						if (audioUrl) {
							// Wait for the audio to finish playing before throwing the error
							await new Promise((resolve, reject) => {
								const audio = new Audio(audioUrl)
								audio.onended = () => {
									URL.revokeObjectURL(audioUrl)
									this.cleanupAudio(audioId)
									resolve()
								}
								audio.onerror = (error) => {
									console.error('Error playing audio:', error)
									URL.revokeObjectURL(audioUrl)
									this.cleanupAudio(audioId)
									reject(error)
								}
								audio.play().catch(reject)
							})
						}
					}
				} catch (error) {
					console.error('Failed to generate or play error audio:', error)
				}

				throw new Error(errorMessageEn)
			}

			progressCallback({ step: 'Generating educational content...', progress: 30 })

			// Generate a title first
			const titleResponse = await openai.chat.completions.create({
				model: "gpt-4o",
				messages: [{
					role: "system",
					content: `You are a children's book title creator. Create a short, engaging title for a children's story about the given topic. The title should be:
1. Short (2-5 words)
2. Engaging for children
3. Related to the topic
4. Easy to understand
Return only the title in ${language}, nothing else (not even quotes).`
				}, {
					role: "user",
					content: `Create a title for a children's story about: ${topic}`
				}],
				max_tokens: 50,
				temperature: 0.7
			})

			const title = titleResponse.choices[0].message.content.trim()

			// Generate educational content in the detected language
			const systemPrompt = `You are a children's educator creating content for young children.
Create an engaging educational text about the given topic. The text should:
1. Introduce the topic very briefly but also interesting for children
2. Include the most importantbasic facts that every child should know (max 10)
3. Add 2-3 interesting or surprising facts
4. Close the text with a short summary that makes children want to learn more

Use simple language and short sentences. Make complex concepts easy to understand.
Return the text in ${language}.`

			// Generate educational content about the topic
			const storyResponse = await openai.chat.completions.create({
				model: "gpt-4o",
				messages: [{
					role: "system",
					content: systemPrompt
				}, {
					role: "user",
					content: `Create an educational text about: ${topic}`
				}],
				max_tokens: 800,
				temperature: 0.7
			})

			progressCallback({ step: 'Educational content generated', progress: 40 })

			// Generate an image for the story
			progressCallback({ step: 'Generating story image...', progress: 45 })

			const imagePrompt = await openai.chat.completions.create({
				model: "gpt-4o",
				messages: [{
					role: "system",
					content: `Create a prompt for DALL-E to generate a child-friendly, educational illustration for a story. The prompt should:
1. Be safe and appropriate for children
2. Focus on educational aspects
3. Request a colorful, engaging style
4. Avoid any scary or inappropriate elements
Return only the prompt, nothing else.`
				}, {
					role: "user",
					content: `Create an image prompt for a children's story about: ${topic}\nTitle: ${title}. The style should be photo-realistic and hyper-detailed, but also positive and cheerful.`
				}],
				max_tokens: 100,
				temperature: 0.7
			})

			const imageResponse = await openai.images.generate({
				model: "dall-e-3",
				prompt: imagePrompt.choices[0].message.content,
				size: "1024x1024",
				quality: "standard",
				n: 1,
				response_format: "b64_json"
			})

			// Initialize imageId as null
			let imageId = null

			if (!imageResponse.data?.[0]?.b64_json) {
				console.error('No base64 image data received:', imageResponse)
			} else {
				// Convert base64 to blob
				try {
					const imageBase64 = imageResponse.data[0].b64_json
					const byteCharacters = atob(imageBase64)
					const byteNumbers = new Array(byteCharacters.length)
					for (let i = 0; i < byteCharacters.length; i++) {
						byteNumbers[i] = byteCharacters.charCodeAt(i)
					}
					const byteArray = new Uint8Array(byteNumbers)
					const imageBlob = new Blob([byteArray], { type: 'image/png' })
					
					// Generate a unique ID for the image
					imageId = `image_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`
					
					// Store the image in IndexedDB
					await storeAudio(imageId, imageBlob)
				} catch (error) {
					console.error('Error processing image data:', error)
					imageId = null
				}
			}

			const story = {
				id: Date.now(),
				title,
				topic,
				content: storyResponse.choices[0].message.content,
				createdAt: new Date(),
				language,
				imageId
			}

			// Generate audio for the content
			progressCallback({ step: 'Generating audio...', progress: 50 })
			try {
				const audioId = await this.textToSpeech(story.content, language)
				story.audioId = audioId
			} catch (error) {
				console.error('Failed to generate audio:', error)
			}

			progressCallback({ step: 'Generating quiz questions...', progress: 70 })

			// Generate quiz cards with a single, clear prompt
			const quizSystemPrompt = `Create 5 educational multiple-choice questions based on the provided text. Follow these important guidelines:

1. Question Requirements:
   - Make questions clear, simple, and direct
   - Focus on key facts and main ideas from the text
   - Use child-friendly language (ages 3-8)
   - Avoid complex vocabulary
   - Questions should test understanding, not just memory

2. Answer Options Requirements:
   - Exactly 3 options per question
   - All options must be:
     * Short (1-8 words)
     * Clear and unambiguous
     * Age-appropriate
     * Similar in length and style
   - Wrong options should be:
     * Plausible but clearly incorrect
     * Not misleading or tricky
     * Related to the topic
   - Avoid negative options (e.g., "not", "isn't", etc.)

3. Educational Value:
   - First question: Test basic understanding of the main topic
   - Second question: Focus on an interesting fact or detail
   - Third question: Test deeper understanding or application
   - Fourth question: Test another interesting fact
   - Fifth question: Test overall comprehension

4. Format Requirements:
   - DO NOT include letter prefixes (A, B, C) in the options
   - Options will be randomly reordered by the system
   - Return a valid JSON array of exactly 5 question objects:
     {
       "question": "string",
       "options": ["string", "string", "string"],
       "correctAnswer": number (0, 1, or 2)
     }

Use the same language as the educational text. Make sure all content is engaging and encouraging for young learners.`

			const cardsResponse = await openai.chat.completions.create({
				model: "gpt-4o",
				messages: [{
					role: "system",
					content: quizSystemPrompt
				}, {
					role: "user",
					content: `Create questions for this educational text: ${story.content}`
				}],
				max_tokens: 500,
				temperature: 0.7
			})

			progressCallback({ step: 'Processing quiz cards...', progress: 80 })

			let cards;
			try {
				const responseText = cardsResponse.choices[0].message.content.trim();
				// Try to extract JSON if it's wrapped in other text
				const jsonMatch = responseText.match(/\[[\s\S]*\]/);
				const jsonStr = jsonMatch ? jsonMatch[0] : responseText;
				cards = JSON.parse(jsonStr);

				// Validate the response structure and format options with letters if not already formatted
				if (!Array.isArray(cards) || cards.length !== 5) {
					throw new Error('Invalid response format: expected array of 5 questions');
				}

				cards = cards.map(card => ({
					...card,
					options: card.options.map(option => {
						// Remove any existing letter prefixes if they exist
						return option.replace(/^[A-C]:\s*/, '')
					})
				}))

				for (const card of cards) {
					if (!card.question || !Array.isArray(card.options) || 
						typeof card.correctAnswer !== 'number' ||
						card.correctAnswer < 0 || card.correctAnswer >= card.options.length) {
						throw new Error('Invalid card format');
					}
				}
			} catch (error) {
				console.error('Failed to parse quiz cards:', error);
				// Create default cards based on the story
				cards = [{
					question: `What is this educational text about?`,
					options: [
						topic,
						'Animals',
						'Space'
					],
					correctAnswer: 0
				}, {
					question: `What was the most interesting fact you learned?`,
					options: [
						'I learned something new!',
						'It was fun',
						'I want to know more'
					],
					correctAnswer: 0
				}, {
					question: `How well did you understand the topic?`,
					options: [
						'Very well',
						'Quite well',
						'I need to learn more'
					],
					correctAnswer: 0
				}, {
					question: `What did you like most about this topic?`,
					options: [
						'The interesting facts',
						'Learning new things',
						'Everything!'
					],
					correctAnswer: 0
				}, {
					question: `Would you like to learn more about this topic?`,
					options: [
						'Yes, please!',
						'Maybe later',
						'I know enough now'
					],
					correctAnswer: 0
				}];
			}

			progressCallback({ step: 'Generating audio for quiz questions...', progress: 90 })

			// Create cards with audio
			const newCards = []

			for (const card of cards) {
				const cardWithAudio = {
					...card,
					id: Date.now() + Math.random(),
					storyId: story.id,
					nextReview: new Date(),
					interval: 1,
					ease: 2.5,
					repetitions: 0,
					language,
					audioId: null // Single audio ID for the entire question
				}

				try {
					// Shuffle options ONCE and track where the correct answer moves to
					const indices = [0, 1, 2]
					for (let i = indices.length - 1; i > 0; i--) {
						const j = Math.floor(Math.random() * (i + 1));
						[indices[i], indices[j]] = [indices[j], indices[i]]
					}
					
					// Update the card with shuffled options and new correct answer index
					cardWithAudio.options = indices.map(i => card.options[i])
					cardWithAudio.correctAnswer = indices.indexOf(card.correctAnswer)

					// Use the SAME shuffled order for audio
					const combinedText = `${card.question}\n\n` + 
						cardWithAudio.options.map((option, index) => 
							`Option ${String.fromCharCode(65 + index)}: ${option}`
						).join('\n---------\n')

					// Generate single audio for the combined text
					const audioId = await this.textToSpeech(combinedText, language)
					if (!audioId) {
						console.error('Failed to generate audio')
						continue
					}
					cardWithAudio.audioId = audioId

					// Only add the card if audio was generated successfully
					newCards.push(cardWithAudio)
					progressCallback({ step: 'Generating quiz audio...', progress: 90 + (newCards.length * 5) })
				} catch (error) {
					console.error('Failed to generate card audio:', error)
					if (cardWithAudio.audioId) {
						await this.cleanupAudio(cardWithAudio.audioId)
					}
				}
			}

			progressCallback({ step: 'Saving...', progress: 95 })

			this.stories.push(story)
			this.cards.push(...newCards)
			this.saveToLocalStorage()

			progressCallback({ step: 'Complete!', progress: 100 })

			return { story, cards: newCards }
		},

		async transcribeAudio(audioBlob) {
			const settingsStore = useSettingsStore()
			if (!settingsStore.openAIKey) {
				throw new Error('OpenAI API key not set. Please add your API key in Settings.')
			}

			// Convert audio blob to File object with proper name and type
			const audioFile = new File([audioBlob], 'recording.webm', {
				type: 'audio/webm',
				lastModified: Date.now()
			})

			const openai = new OpenAI({
				apiKey: settingsStore.openAIKey,
				dangerouslyAllowBrowser: true
			})

			const transcription = await openai.audio.transcriptions.create({
				file: audioFile,
				model: 'whisper-1',
				response_format: 'text'
			})

			return transcription
		},

		async textToSpeech(text, language = null) {
			const settingsStore = useSettingsStore()
			if (!settingsStore.openAIKey) {
				throw new Error('OpenAI API key not set. Please add your API key in Settings.')
			}

			// Limit text length to avoid issues with the API
			const maxLength = 4000
			if (text.length > maxLength) {
				text = text.substring(0, maxLength)
			}

			// Map languages to appropriate voices
			const voiceMap = {
				en: "nova",
				de: "onyx",
				es: "alloy",
				fr: "echo",
			}

			const effectiveLanguage = language || navigator.language.split('-')[0] || 'en'
			const voice = voiceMap[effectiveLanguage] || "nova"

			const openai = new OpenAI({
				apiKey: settingsStore.openAIKey,
				dangerouslyAllowBrowser: true
			})

			try {
				const response = await openai.audio.speech.create({
					model: "tts-1",
					voice: voice,
					input: text,
				})

				// Get the binary audio data
				const audioData = await response.arrayBuffer()
				const audioBlob = new Blob([audioData], { type: 'audio/mpeg' })
				
				// Generate a unique ID for this audio
				const audioId = `audio_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`
				
				// Store the audio in IndexedDB
				await storeAudio(audioId, audioBlob)
				
				return audioId
			} catch (error) {
				console.error('Failed to generate speech:', error)
				throw new Error('Failed to generate speech. Please try again.')
			}
		},

		async getAudioUrl(id) {
			try {
				const audioBlob = await getAudio(id)
				if (!audioBlob) {
					console.error('Failed to get audio blob for id:', id)
					return null
				}

				if (!(audioBlob instanceof Blob)) {
					console.error('Invalid audio data - not a Blob:', audioBlob)
					return null
				}

				// Ensure we have a valid audio MIME type
				let type = audioBlob.type || 'audio/mpeg'
				if (!type.startsWith('audio/')) {
					type = 'audio/mpeg'
				}

				// Always create a new blob with the correct MIME type to ensure consistency
				const newBlob = new Blob([audioBlob], { type })
				const url = URL.createObjectURL(newBlob)
				
				// Log the actual blob details for debugging
				console.log('Created blob URL for audio:', { 
					id, 
					type: newBlob.type,
					size: newBlob.size,
					url: url.startsWith('blob:') ? 'Valid blob URL' : 'Invalid URL format'
				})
				
				return url
			} catch (error) {
				console.error('Error creating audio URL:', error)
				return null
			}
		},

		async cleanupAudio(audioId) {
			try {
				await deleteAudio(audioId)
			} catch (error) {
				console.error('Failed to delete audio:', error)
			}
		},

		async getImageUrl(imageId) {
			try {
				if (!imageId) {
					console.log('No imageId provided')
					return null
				}

				const imageBlob = await getAudio(imageId)
				if (!imageBlob) {
					console.log('No image found for id:', imageId)
					return null
				}

				if (!(imageBlob instanceof Blob)) {
					console.error('Retrieved data is not a Blob:', imageBlob)
					return null
				}

				// Ensure we have a valid image MIME type
				let type = imageBlob.type || 'image/png'
				if (!type.startsWith('image/')) {
					type = 'image/png'
				}

				// Always create a new blob with the correct MIME type
				const newBlob = new Blob([imageBlob], { type })
				const url = URL.createObjectURL(newBlob)

				// Log the actual blob details for debugging
				console.log('Created image URL:', { 
					id: imageId, 
					type: newBlob.type,
					size: newBlob.size,
					url: url.startsWith('blob:') ? 'Valid blob URL' : 'Invalid URL format'
				})

				return url
			} catch (error) {
				console.error('Failed to get image:', error)
				return null
			}
		},

		async exportStory(storyId) {
			try {
				// Find the story
				const story = this.stories.find(s => s.id === storyId)
				if (!story) throw new Error('Story not found')

				// Find associated cards
				const cards = this.cards.filter(card => card.storyId === storyId)

				// Get audio data and image data
				const audioFiles = {}
				
				// Get story audio
				if (story.audioId) {
					const storyAudioBlob = await getAudio(story.audioId)
					if (storyAudioBlob) {
						audioFiles[story.audioId] = Array.from(new Uint8Array(await storyAudioBlob.arrayBuffer()))
					}
				}

				// Get story image
				if (story.imageId) {
					const imageBlob = await getAudio(story.imageId)
					if (imageBlob) {
						audioFiles[story.imageId] = Array.from(new Uint8Array(await imageBlob.arrayBuffer()))
					}
				}

				// Get all card audio parts
				for (const card of cards) {
					if (card.audioId) {
						const audioBlob = await getAudio(card.audioId)
						if (audioBlob) {
							audioFiles[card.audioId] = Array.from(new Uint8Array(await audioBlob.arrayBuffer()))
						}
					}
				}

				// Create export data structure
				const exportData = {
					story: {
						...story,
						createdAt: story.createdAt.toISOString()
					},
					cards: cards.map(card => ({
						...card,
						nextReview: card.nextReview.toISOString()
					})),
					audioFiles
				}

				// Convert to JSON and create Blob
				const jsonBlob = new Blob([JSON.stringify(exportData)], { type: 'application/json' })
				
				// Create download link
				const downloadUrl = URL.createObjectURL(jsonBlob)
				const downloadLink = document.createElement('a')
				downloadLink.href = downloadUrl
				downloadLink.download = `story_${story.topic.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.json`
				
				// Trigger download
				document.body.appendChild(downloadLink)
				downloadLink.click()
				document.body.removeChild(downloadLink)
				
				// Cleanup
				URL.revokeObjectURL(downloadUrl)
				
				return true
			} catch (error) {
				console.error('Failed to export story:', error)
				throw new Error('Failed to export story. Please try again.')
			}
		},

		async importStory(importData) {
			try {
				const { story, cards, audioFiles } = importData

				// Convert date strings back to Date objects
				story.createdAt = new Date(story.createdAt)
				cards.forEach(card => {
					card.nextReview = new Date(card.nextReview)
					
					// Ensure audioParts structure exists
					if (!card.audioId) {
						card.audioId = null
					}
				})

				// Store audio files and images in IndexedDB
				for (const [id, array] of Object.entries(audioFiles)) {
					const buffer = new Uint8Array(array).buffer
					const blob = new Blob([buffer], { 
						type: id.startsWith('image_') ? 'image/png' : 'audio/mpeg'
					})
					await storeAudio(id, blob)
				}

				// Add story and cards to state
				this.stories.push(story)
				this.cards.push(...cards)
				this.saveToLocalStorage()

				return true
			} catch (error) {
				console.error('Failed to import story:', error)
				throw new Error('Failed to import story. Please try again.')
			}
		},

		getDueCards() {
			const now = new Date()
			return this.cards.filter(card => card.nextReview <= now)
		},

		updateCardProgress(cardId, wasCorrect) {
			const card = this.cards.find(c => c.id === cardId)
			if (!card) return

			// SM-2 algorithm implementation
			if (wasCorrect) {
				if (card.repetitions === 0) {
					card.interval = 1
				} else if (card.repetitions === 1) {
					card.interval = 6
				} else {
					card.interval = Math.round(card.interval * card.ease)
				}
				card.repetitions++
				card.ease = Math.max(1.3, card.ease + 0.1)
			} else {
				card.repetitions = 0
				card.interval = 1
				card.ease = Math.max(1.3, card.ease - 0.2)
			}

			card.nextReview = new Date(Date.now() + card.interval * 24 * 60 * 60 * 1000)
			this.completedToday++
			
			// Update experience points and level
			this.experiencePoints += wasCorrect ? 10 : 2
			this.currentLevel = Math.floor(Math.sqrt(this.experiencePoints / 100)) + 1
			this.saveToLocalStorage()
		},

		resetDailyProgress() {
			const today = new Date().toDateString()
			if (this.lastReviewDate !== today) {
				this.completedToday = 0
				this.lastReviewDate = today
				this.saveToLocalStorage()
			}
		},

		deleteStory(storyId) {
			// Remove the story
			const storyIndex = this.stories.findIndex(s => s.id === storyId)
			if (storyIndex === -1) return

			const story = this.stories[storyIndex]
			
			// Clean up story audio URL if it exists
			if (story.audioId) {
				this.cleanupAudio(story.audioId)
			}

			// Remove the story
			this.stories.splice(storyIndex, 1)

			// Remove associated cards
			this.cards = this.cards.filter(card => card.storyId !== storyId)

			this.saveToLocalStorage()
		},

		getCardsForPractice(limit = 10) {
			// Get all cards that are not due today
			const now = new Date()
			const availableCards = this.cards.filter(card => {
				const dueDate = new Date(card.nextReview)
				return dueDate > now
			})
			
			// Sort by:
			// 1. Cards with lower repetitions (prioritize less practiced cards)
			// 2. Cards with lower ease (prioritize harder cards)
			const sortedCards = availableCards.sort((a, b) => {
				// First compare repetitions
				if (a.repetitions !== b.repetitions) {
					return a.repetitions - b.repetitions
				}
				
				// Then compare ease
				return a.ease - b.ease
			})
			
			return sortedCards.slice(0, limit)
		}
	},

	getters: {
		dailyProgress: (state) => (state.completedToday / state.dailyGoal) * 100,
		hasCardsToReview: (state) => state.getDueCards().length > 0,
		allStories: (state) => state.stories.sort((a, b) => b.createdAt - a.createdAt),
		isApiKeySet: () => {
			const settingsStore = useSettingsStore()
			return !!settingsStore.openAIKey
		}
	}
}) 