<template>
  <div class="stories-container">
    <div v-if="!isApiKeySet" class="api-key-message">
      <h2>OpenAI API Key Required</h2>
      <p>To use the Stories and Quiz features, you need to add your OpenAI API key in the settings.</p>
      <router-link to="/settings">
        <BabyButton :handler="goToSettings" class="settings-button">
          Go to Settings
        </BabyButton>
      </router-link>
    </div>

    <template v-else>
      <div class="record-section">
        <button 
          class="record-button"
          @mousedown="startRecording"
          @mouseup="stopRecording"
          @mouseleave="stopRecording"
          @touchstart.prevent="handleTouchStart"
          @touchend.prevent="handleTouchEnd"
          @touchcancel.prevent="handleTouchEnd"
          @touchmove.prevent="handleTouchMove"
          :disabled="isGenerating"
          :class="{ 'too-short': recordingTooShort }"
        >
          {{ isRecording ? '🎤 Recording...' : recordingTooShort ? '🎤 Hold longer!' : '🎤 Hold to Record' }}
        </button>
        <div v-if="isRecording" class="record-visualization">
          <div 
            v-for="i in 10" 
            :key="i" 
            class="bar"
            :style="{ height: `${Math.random() * 50 + 10}px` }"
          ></div>
        </div>
        <p v-if="recordingTooShort" class="error-message">
          Please hold for at least 1 second
        </p>
      </div>

      <!-- Generation Progress -->
      <div v-if="isGenerating" class="generation-progress">
        <div class="progress-bar">
          <div 
            class="progress-fill"
            :style="{ width: `${generationProgress}%` }"
          ></div>
        </div>
        <p class="progress-step">{{ generationStep }}</p>
      </div>

      <div class="stories-grid">
        <div 
          v-for="story in stories" 
          :key="story.id" 
          class="story-tile"
          @click="navigateToStory(story)"
          @touchstart.prevent="handleStoryTouchStart(story)"
          @touchend.prevent="handleStoryTouchEnd(story)"
        >
          <img 
            v-if="storyImages[story.id]" 
            :src="storyImages[story.id]" 
            class="story-image" 
            alt=""
          />
          <div class="story-content">
            <h3>{{ story.title || story.topic }}</h3>
            <p class="story-date">{{ formatDate(story.createdAt) }}</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { useSpaceRepetitionStore } from '../stores/spaceRepetitionStore'
import { useSettingsStore } from '../stores/settingsStore'
import BabyButton from '@/components/BabyButton.vue'
import { OpenAI } from 'openai'

export default {
	name: 'StoriesView',
	components: {
		BabyButton
	},
	setup() {
		const router = useRouter()
		const spaceRepetitionStore = useSpaceRepetitionStore()
		const settingsStore = useSettingsStore()
		const isRecording = ref(false)
		const mediaRecorder = ref(null)
		const audioChunks = ref([])
		const isGenerating = ref(false)
		const generationProgress = ref(0)
		const generationStep = ref('')
		const recordingStartTime = ref(null)
		const recordingTooShort = ref(false)
		const audioPlayer = ref(null)
		const isPlayingGreeting = ref(false)
		const storyImages = ref({})
		const touchStartPosition = ref(null)
		const touchMoveThreshold = 20 // pixels

		const stories = computed(() => spaceRepetitionStore.allStories)
		const isApiKeySet = computed(() => spaceRepetitionStore.isApiKeySet)

		const browserLanguage = computed(() => navigator.language.split('-')[0] || 'en')

		const greeting = computed(() => 
			"What do you want to learn? You can request a new topic by holding the button while saying what you are curious about."
		)

		const waitingMessage = computed(() => 
			"Please wait a moment until the progress bar is full."
		)

		const getCachedAudio = async (key, text, language) => {
			try {
				// Try to get from IndexedDB first
				const audioId = localStorage.getItem(`cached_audio_${key}_${language}`)
				if (audioId) {
					const audioUrl = await spaceRepetitionStore.getAudioUrl(audioId)
					if (audioUrl) {
						return { audioId, audioUrl }
					}
				}

				// If not found or invalid, generate new audio
				const openai = new OpenAI({
					apiKey: settingsStore.openAIKey,
					dangerouslyAllowBrowser: true
				})

				// Translate the text
				const translationResponse = await openai.chat.completions.create({
					model: "gpt-4",
					messages: [{
						role: "system",
						content: "You are a translator. Translate the given text naturally into the target language. You are talking to a child. Return only the translation, nothing else."
					}, {
						role: "user",
						content: `Translate to ${language}: ${text}`
					}],
					max_tokens: 200,
					temperature: 0.3
				})

				const translatedText = translationResponse.choices[0].message.content.trim()
				
				// Generate audio from translated text
				const newAudioId = await spaceRepetitionStore.textToSpeech(translatedText, language)
				const newAudioUrl = await spaceRepetitionStore.getAudioUrl(newAudioId)

				// Cache the audio ID
				localStorage.setItem(`cached_audio_${key}_${language}`, newAudioId)

				return { audioId: newAudioId, audioUrl: newAudioUrl }
			} catch (error) {
				console.error('Error getting cached audio:', error)
				return null
			}
		}

		const playAudioMessage = async (key, text) => {
			if (!isApiKeySet.value || !navigator.onLine || isPlayingGreeting.value) return

			try {
				isPlayingGreeting.value = true
				
				const audioData = await getCachedAudio(key, text, browserLanguage.value)
				if (!audioData) {
					console.error('Failed to get audio')
					return
				}

				audioPlayer.value = new Audio(audioData.audioUrl)
				await audioPlayer.value.play()

				// Clean up after playing
				audioPlayer.value.onended = () => {
					URL.revokeObjectURL(audioData.audioUrl)
					isPlayingGreeting.value = false
				}
			} catch (error) {
				console.error('Error playing audio:', error)
				isPlayingGreeting.value = false
			}
		}

		const playGreeting = () => playAudioMessage('greeting', greeting.value)
		const playWaitingMessage = () => playAudioMessage('waiting', waitingMessage.value)

		const startRecording = async () => {
			if (isGenerating.value) return

			// Stop any playing audio
			if (audioPlayer.value) {
				audioPlayer.value.pause()
				if (audioPlayer.value.src && audioPlayer.value.src.startsWith('blob:')) {
					URL.revokeObjectURL(audioPlayer.value.src)
				}
				audioPlayer.value.src = ''
				isPlayingGreeting.value = false
			}

			try {
				const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
				mediaRecorder.value = new MediaRecorder(stream)
				audioChunks.value = []
				recordingTooShort.value = false
				
				mediaRecorder.value.ondataavailable = (event) => {
					audioChunks.value.push(event.data)
				}

				mediaRecorder.value.start()
				isRecording.value = true
				recordingStartTime.value = Date.now()
			} catch (error) {
				console.error('Error accessing microphone:', error)
			}
		}

		const stopRecording = async () => {
			if (!mediaRecorder.value || mediaRecorder.value.state === 'inactive') return

			const recordingDuration = Date.now() - recordingStartTime.value
			if (recordingDuration < 1000) {
				recordingTooShort.value = true
				mediaRecorder.value.stop()
				isRecording.value = false
				mediaRecorder.value.stream.getTracks().forEach(track => track.stop())
				audioChunks.value = []
				return
			}

			mediaRecorder.value.stop()
			isRecording.value = false
			recordingTooShort.value = false

			mediaRecorder.value.onstop = async () => {
				const audioBlob = new Blob(audioChunks.value, { type: 'audio/webm' })
				try {
					isGenerating.value = true
					generationProgress.value = 0
					generationStep.value = 'Transcribing audio...'

					// Play waiting message
					await playWaitingMessage()

					const transcription = await spaceRepetitionStore.transcribeAudio(audioBlob)
					
					generationProgress.value = 10
					const result = await spaceRepetitionStore.generateStoryAndCards(
						transcription,
						({ step, progress }) => {
							generationStep.value = step
							generationProgress.value = progress
						}
					)
					
					navigateToStory(result.story)
				} catch (error) {
					console.error('Error processing audio:', error)
				} finally {
					// Clean up
					mediaRecorder.value.stream.getTracks().forEach(track => track.stop())
					audioChunks.value = []
					isGenerating.value = false
				}
			}
		}

		const navigateToStory = (story) => {
			router.push(`/stories/${story.id}`)
		}

		const formatDate = (date) => {
			return new Date(date).toLocaleDateString()
		}

		const loadStoryImages = async () => {
			for (const story of stories.value) {
				if (story.imageId) {
					try {
						const imageUrl = await spaceRepetitionStore.getImageUrl(story.imageId)
						if (imageUrl) {
							storyImages.value[story.id] = imageUrl
						}
					} catch (error) {
						console.error('Error loading image for story:', story.id, error)
					}
				}
			}
		}

		// Watch for changes in stories and reload images
		watch(() => stories.value, async () => {
			await loadStoryImages()
		}, { immediate: true })

		// Clean up object URLs when component is unmounted
		onUnmounted(() => {
			Object.values(storyImages.value).forEach(url => {
				if (url) URL.revokeObjectURL(url)
			})
		})

		onMounted(async () => {
			playGreeting()
			await loadStoryImages()
		})

		const handleTouchStart = async (event) => {
			touchStartPosition.value = {
				x: event.touches[0].clientX,
				y: event.touches[0].clientY
			}
			await startRecording()
		}

		const handleTouchMove = (event) => {
			if (!touchStartPosition.value || !isRecording.value) return

			const currentPosition = {
				x: event.touches[0].clientX,
				y: event.touches[0].clientY
			}

			const distance = Math.sqrt(
				Math.pow(currentPosition.x - touchStartPosition.value.x, 2) +
				Math.pow(currentPosition.y - touchStartPosition.value.y, 2)
			)

			// If finger moved more than threshold, stop recording
			if (distance > touchMoveThreshold) {
				stopRecording()
				touchStartPosition.value = null
			}
		}

		const handleTouchEnd = async () => {
			touchStartPosition.value = null
			await stopRecording()
		}

		const goToSettings = () => {
			router.push('/settings')
		}

		const handleStoryTouchStart = (story) => {
			touchStartPosition.value = {
				story,
				time: Date.now()
			}
		}

		const handleStoryTouchEnd = (story) => {
			if (touchStartPosition.value?.story?.id === story.id) {
				const touchDuration = Date.now() - touchStartPosition.value.time
				if (touchDuration < 500) { // Only navigate if touch was less than 500ms
					navigateToStory(story)
				}
			}
			touchStartPosition.value = null
		}

		return {
			isRecording,
			isGenerating,
			generationProgress,
			generationStep,
			stories,
			startRecording,
			stopRecording,
			navigateToStory,
			formatDate,
			isApiKeySet,
			recordingTooShort,
			greeting,
			isPlayingGreeting,
			storyImages,
			handleTouchStart,
			handleTouchEnd,
			handleTouchMove,
			goToSettings,
			handleStoryTouchStart,
			handleStoryTouchEnd,
		}
	}
}
</script>

<style scoped>
.stories-container {
  padding: 2rem;
}

.api-key-message {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
}

.api-key-message h2 {
  color: var(--orange);
  margin-bottom: 1rem;
}

.api-key-message p {
  margin-bottom: 2rem;
  line-height: 1.5;
}

.settings-button {
  font-size: 1.5em;
  padding: 1rem 2rem;
}

.greeting-section {
  text-align: center;
  margin-bottom: 2rem;
  animation: fadeIn 0.5s ease-out;
}

.greeting-section h2 {
  color: var(--orange);
  font-size: 2em;
  margin-bottom: 1rem;
}

.greeting-section p {
  color: #666;
  font-size: 1.2em;
  line-height: 1.5;
  max-width: 800px;
  margin: 0 auto;
}

.record-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.record-button {
  font-size: 2em;
  padding: 1rem 2rem;
  border-radius: 50px;
  border: none;
  background: var(--orange);
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  touch-action: none;
  min-height: 80px;
  will-change: transform;
}

.record-button:active {
  transform: scale(0.95);
}

.record-visualization {
  display: flex;
  gap: 5px;
  align-items: center;
  height: 60px;
  margin-top: 1rem;
}

.bar {
  width: 10px;
  background: var(--orange);
  border-radius: 5px;
  transition: height 0.1s;
}

.stories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 2rem;
}

.story-tile {
  background: white;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.story-tile:hover {
  transform: translateY(-5px);
}

.story-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.story-content {
  padding: 1rem 2rem;
}

.story-icon {
  font-size: 2em;
  margin-bottom: 1rem;
}

.story-date {
  color: #666;
  font-size: 0.9em;
  margin-top: 0.5rem;
}

.generation-progress {
  max-width: 600px;
  margin: 2rem auto;
  text-align: center;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background: #eee;
  border-radius: 10px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: var(--orange);
  transition: width 0.3s ease;
}

.progress-step {
  margin-top: 1rem;
  color: var(--orange);
  font-weight: 500;
}

.record-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.record-button.too-short {
  background-color: #ff6b6b;
  animation: shake 0.5s;
}

.error-message {
  color: #ff6b6b;
  text-align: center;
  margin-top: 0.5rem;
  font-size: 0.9em;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style> 