<template>
	<div class="quiz-container">
		<div v-if="!isApiKeySet" class="api-key-message">
			<h2>OpenAI API Key Required</h2>
			<p>To use the Stories and Quiz features, you need to add your OpenAI API key in the settings.</p>
			<router-link to="/settings">
				<BabyButton :handler="goToSettings" class="settings-button">
					Go to Settings
				</BabyButton>
			</router-link>
		</div>

		<template v-else>
			<!-- Start Screen -->
			<div class="center" v-if="mode === 'start'">
				<div class="progress-section">
					<div class="progress-info">
						<h2>Daily Progress</h2>
						<div class="progress-bar">
							<div 
								class="progress-fill" 
								:style="{ width: `${dailyProgress}%` }"
							></div>
						</div>
						<p>{{ completedToday }} / {{ dailyGoal }} cards reviewed</p>
					</div>
					<div class="level-info">
						<h3>Level {{ currentLevel }}</h3>
						<p>{{ experiencePoints }} XP</p>
					</div>
				</div>

				<BabyButton 
					v-if="hasCardsToReview"
					:handler="startQuiz" 
					class="startButton"
				>
					Start Quiz
				</BabyButton>
				<div v-else class="button-group">
					<router-link 
						to="/stories"
						class="explore-link"
					>
						<BabyButton 
							:handler="goToStories"
							class="startButton"
						>
							Explore Stories
						</BabyButton>
					</router-link>
					<BabyButton 
						:handler="startPracticeMode" 
						class="practiceButton"
					>
						Practice Mode
					</BabyButton>
				</div>

				<router-link to="/stories" class="stories-link">
					Go to Stories
				</router-link>
			</div>

			<!-- Finish Screen -->
			<div class="center" v-if="mode === 'finish'">
				<div class="finish-message">
					<template v-if="isPracticeMode">
						<h2>Practice Complete! 🌟</h2>
						<p>Keep practicing to improve your knowledge!</p>
					</template>
					<template v-else>
						<h2>Great job! 🎉</h2>
						<p>You've completed today's review!</p>
					</template>
				</div>
				<div class="button-group">
					<BabyButton 
						v-if="isPracticeMode"
						:handler="startPracticeMode" 
						class="restartButton"
					>
						Practice More
					</BabyButton>
					<BabyButton 
						v-else-if="hasCardsToReview"
						:handler="startQuiz" 
						class="restartButton"
					>
						More Cards
					</BabyButton>
					<router-link 
						v-else 
						to="/stories"
						class="explore-link"
					>
						<BabyButton 
							:handler="goToStories"
							class="restartButton"
						>
							Explore Stories
						</BabyButton>
					</router-link>
				</div>
			</div>

			<!-- Quiz Screen -->
			<div class="question" v-if="mode !== 'start' && mode !== 'finish'">
				<div class="progress-bar">
					<div 
						class="progress-fill" 
						:style="{ width: `${(currentQuestionIndex / totalQuestions) * 100}%` }"
					></div>
				</div>

				<div 
					v-if="showingFeedback" 
					class="feedback-animation"
					:class="{ 'correct': lastAnswerCorrect, 'incorrect': !lastAnswerCorrect }"
				>
					<div v-if="lastAnswerCorrect">
						<div>✨ Correct! ✨</div>
						<div class="xp-gain">+{{ xpGainPerCorrectAnswer }} XP</div>
					</div>
					<div v-else>❌ Try Again ❌</div>
				</div>

				<div v-else class="question-content">
					<BabyButton 
						:handler="playQuestion" 
						class="questionButton"
						:disabled="isPlaying"
					>
						{{ isPlaying ? '🔊' : '🔉' }}
					</BabyButton>

					<div class="options-grid">
						<BabyButton 
							v-for="(option, index) in currentQuestion.options" 
							:key="index"
							:handler="() => tryOption(index)"
							class="answerButton"
							:disabled="isPlaying"
						>
							{{ String.fromCharCode(65 + index) }}
						</BabyButton>
					</div>
				</div>
			</div>
		</template>

		<!-- Hidden audio player -->
		<audio ref="audioPlayer" @ended="handleAudioEnded" @error="handleAudioError" class="hidden"></audio>
	</div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useSpaceRepetitionStore } from '../stores/spaceRepetitionStore'
import BabyButton from '@/components/BabyButton.vue'
import { useRouter } from 'vue-router'

export default {
	name: 'Quiz',
	components: {
		BabyButton
	},
	setup() {
		const spaceRepetitionStore = useSpaceRepetitionStore()
		const mode = ref('start')
		const currentQuestionIndex = ref(0)
		const currentCards = ref([])
		const currentQuestion = ref(null)
		const audioPlayer = ref(null)
		const isPlaying = ref(false)
		const isPracticeMode = ref(false)
		const audioContext = ref(null)
		const showingFeedback = ref(false)
		const lastAnswerCorrect = ref(false)
		const cleanupController = ref(null)
		const router = useRouter()

		// Computed properties
		const hasCardsToReview = computed(() => spaceRepetitionStore.hasCardsToReview)
		const dailyProgress = computed(() => spaceRepetitionStore.dailyProgress)
		const completedToday = computed(() => spaceRepetitionStore.completedToday)
		const dailyGoal = computed(() => spaceRepetitionStore.dailyGoal)
		const currentLevel = computed(() => spaceRepetitionStore.currentLevel)
		const experiencePoints = computed(() => spaceRepetitionStore.experiencePoints)
		const totalQuestions = computed(() => currentCards.value.length)
		const isApiKeySet = computed(() => spaceRepetitionStore.isApiKeySet)
		const xpGainPerCorrectAnswer = computed(() => 10) // Base XP gain for correct answers

		const setCurrentQuestion = (card) => {
			// Preserve the hasBeenPlayed flag if it exists
			const hasBeenPlayed = currentQuestion.value?.id === card.id ? currentQuestion.value.hasBeenPlayed : false

			currentQuestion.value = {
				...card,
				hasBeenPlayed
			}
		}

		// Initialize AudioContext on first interaction
		const initAudioContext = async () => {
			try {
				if (!audioContext.value) {
					audioContext.value = new (window.AudioContext || window.webkitAudioContext)()
					// Resume context if it's not running (needed for some browsers)
					if (audioContext.value.state !== 'running') {
						await audioContext.value.resume()
					}
					console.log('AudioContext initialized:', audioContext.value.state)
				}
			} catch (error) {
				console.error('Failed to initialize AudioContext:', error)
			}
		}

		const playBeep = (frequency, duration) => {
			initAudioContext()
			const oscillator = audioContext.value.createOscillator()
			const gainNode = audioContext.value.createGain()
			
			oscillator.connect(gainNode)
			gainNode.connect(audioContext.value.destination)
			
			oscillator.type = 'sine'
			oscillator.frequency.setValueAtTime(frequency, audioContext.value.currentTime)
			
			// Add fade out to avoid clicks
			gainNode.gain.setValueAtTime(0.5, audioContext.value.currentTime)
			gainNode.gain.exponentialRampToValueAtTime(0.01, audioContext.value.currentTime + duration)
			
			oscillator.start(audioContext.value.currentTime)
			oscillator.stop(audioContext.value.currentTime + duration)
		}

		const playFeedbackSound = async (isCorrect) => {
			try {
				// Wait for any current audio to finish and clean up
				if (isPlaying.value && audioPlayer.value) {
					audioPlayer.value.pause()
					if (audioPlayer.value.src && audioPlayer.value.src.startsWith('blob:')) {
						URL.revokeObjectURL(audioPlayer.value.src)
					}
					audioPlayer.value.src = ''
				}

				// Play feedback beep
				if (isCorrect) {
					// Correct answer: Play ascending notes
					playBeep(440, 0.15) // A4
					setTimeout(() => playBeep(554.37, 0.15), 150) // C#5
					setTimeout(() => playBeep(659.25, 0.3), 300) // E5
				} else {
					// Wrong answer: Play descending notes
					playBeep(440, 0.15) // A4
					setTimeout(() => playBeep(392, 0.15), 150) // G4
					setTimeout(() => playBeep(349.23, 0.3), 300) // F4
				}

				// Wait for the feedback sound to finish
				await new Promise(resolve => setTimeout(resolve, 600))
			} catch (error) {
				console.error('Error playing feedback sound:', error)
			}
		}

		// Cleanup function that can be called from anywhere
		const cleanup = () => {
			try {
				if (cleanupController.value) {
					cleanupController.value.abort()
				}
				if (audioPlayer.value) {
					audioPlayer.value.pause()
					audioPlayer.value.currentTime = 0
					if (audioPlayer.value.src && audioPlayer.value.src.startsWith('blob:')) {
						URL.revokeObjectURL(audioPlayer.value.src)
					}
					// Set to empty audio to prevent loading base URL
					audioPlayer.value.src = 'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA'
				}
				isPlaying.value = false
			} catch (error) {
				console.error('Error during cleanup:', error)
			}
		}

		// Register cleanup on component unmount
		onUnmounted(cleanup)

		const startQuiz = () => {
			initAudioContext() // Initialize audio context on quiz start
			currentCards.value = spaceRepetitionStore.getDueCards()
			if (currentCards.value.length === 0) {
				mode.value = 'finish'
				return
			}
			currentQuestionIndex.value = 0
			setCurrentQuestion(currentCards.value[0])
			mode.value = 'quiz'
			isPracticeMode.value = false
			playQuestion() // Auto-play first question
		}

		const startPracticeMode = () => {
			initAudioContext() // Initialize audio context on practice start
			currentCards.value = spaceRepetitionStore.getCardsForPractice()
			if (currentCards.value.length === 0) {
				mode.value = 'finish'
				isPracticeMode.value = true
				return
			}
			currentQuestionIndex.value = 0
			setCurrentQuestion(currentCards.value[0])
			mode.value = 'quiz'
			isPracticeMode.value = true
			playQuestion() // Auto-play first question
		}

		const playQuestion = async () => {
			if (!currentQuestion.value) {
				console.warn('No current question to play')
				return
			}

			try {
				await initAudioContext()
				
				if (!currentQuestion.value.audioId) {
					console.error('No audio available for question')
					return
				}

				// Create a new cleanup controller
				cleanupController.value = new AbortController()
				const signal = cleanupController.value.signal

				if (signal.aborted) {
					console.log('Playback aborted')
					return
				}

				try {
					console.log('Fetching audio:', currentQuestion.value.audioId)
					const url = await spaceRepetitionStore.getAudioUrl(currentQuestion.value.audioId)
					
					if (!url) {
						console.error('Failed to get audio URL')
						return
					}

					console.log('Setting audio source:', url)
					audioPlayer.value.src = url
					isPlaying.value = true
					
					console.log('Playing audio')
					await audioPlayer.value.play()
					
					// Wait for the audio to finish
					await new Promise((resolve, reject) => {
						const onEnded = () => {
							console.log('Audio finished')
							audioPlayer.value.removeEventListener('ended', onEnded)
							audioPlayer.value.removeEventListener('error', onError)
							resolve()
						}
						const onError = (error) => {
							console.error('Audio playback error:', error)
							audioPlayer.value.removeEventListener('ended', onEnded)
							audioPlayer.value.removeEventListener('error', onError)
							reject(error)
						}
						audioPlayer.value.addEventListener('ended', onEnded)
						audioPlayer.value.addEventListener('error', onError)
					})

					// Cleanup the URL after playing
					if (url.startsWith('blob:')) {
						URL.revokeObjectURL(url)
						// Set to empty audio to prevent loading base URL
						audioPlayer.value.src = 'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA'
					}
				} catch (error) {
					console.error('Error playing audio:', error)
				}

				// Mark as played only after successful playback
				if (!signal.aborted) {
					currentQuestion.value.hasBeenPlayed = true
				}
			} catch (error) {
				console.error('Error in playQuestion:', error)
			} finally {
				isPlaying.value = false
			}
		}

		const tryOption = async (selectedIndex) => {
			if (showingFeedback.value) return

			const isCorrect = selectedIndex === currentQuestion.value.correctAnswer
			lastAnswerCorrect.value = isCorrect
			showingFeedback.value = true

			// Use the original card from currentCards when updating progress
			const originalCard = currentCards.value[currentQuestionIndex.value]
			
			if (isCorrect) {
				// Update progress for correct answers
				spaceRepetitionStore.updateCardProgress(originalCard.id, true)
			} else {
				// For wrong answers:
				// 1. Reset the card's progress by updating with false
				spaceRepetitionStore.updateCardProgress(originalCard.id, false)
				// 2. Add the card back to the queue at a random position between current and end
				const remainingCards = currentCards.value.slice(currentQuestionIndex.value + 1)
				const insertPosition = currentQuestionIndex.value + 1 + Math.floor(Math.random() * (remainingCards.length + 1))
				currentCards.value.splice(insertPosition, 0, originalCard)
			}

			// Play feedback sound and wait for it to finish
			await playFeedbackSound(isCorrect)

			// Show feedback for a moment before moving to next question
			await new Promise(resolve => setTimeout(resolve, 1000))
			showingFeedback.value = false

			// Move to next question or finish
			currentQuestionIndex.value++
			if (currentQuestionIndex.value < currentCards.value.length) {
				setCurrentQuestion(currentCards.value[currentQuestionIndex.value])
				// Auto-play next question after a short delay
				setTimeout(() => playQuestion(), 500)
			} else {
				// Keep practice mode state when transitioning to finish screen
				const wasPracticeMode = isPracticeMode.value
				mode.value = 'finish'
				isPracticeMode.value = wasPracticeMode
			}
		}

		const handleAudioEnded = () => {
			isPlaying.value = false
			// Only clean up blob URLs, not the feedback sound URLs
			if (audioPlayer.value?.src && audioPlayer.value.src.startsWith('blob:')) {
				URL.revokeObjectURL(audioPlayer.value.src)
				// Set to empty audio to prevent loading base URL
				audioPlayer.value.src = 'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA'
			}
		}

		const handleAudioError = () => {
			isPlaying.value = false
			// Only clean up blob URLs, not the feedback sound URLs
			if (audioPlayer.value?.src && audioPlayer.value.src.startsWith('blob:')) {
				URL.revokeObjectURL(audioPlayer.value.src)
				// Set to empty audio to prevent loading base URL
				audioPlayer.value.src = 'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA'
			}
		}

		const goToSettings = () => {
			router.push('/settings')
		}

		const goToStories = () => {
			router.push('/stories')
		}

		onMounted(() => {
			spaceRepetitionStore.resetDailyProgress()
		})

		return {
			mode,
			isPracticeMode,
			currentQuestion,
			currentQuestionIndex,
			totalQuestions,
			hasCardsToReview,
			dailyProgress,
			completedToday,
			dailyGoal,
			currentLevel,
			experiencePoints,
			startQuiz,
			startPracticeMode,
			playQuestion,
			tryOption,
			isApiKeySet,
			audioPlayer,
			isPlaying,
			handleAudioEnded,
			handleAudioError,
			showingFeedback,
			lastAnswerCorrect,
			xpGainPerCorrectAnswer,
			goToSettings,
			goToStories
		}
	}
}
</script>

<style scoped>
.quiz-container {
	height: calc(100vh- 2rem);
	padding: 2rem;
}

.api-key-message {
	text-align: center;
	max-width: 600px;
	margin: 0 auto;
	padding: 2rem;
}

.api-key-message h2 {
	color: var(--orange);
	margin-bottom: 1rem;
}

.api-key-message p {
	margin-bottom: 2rem;
	line-height: 1.5;
}

.settings-button {
	font-size: 1.5em;
	padding: 1rem 2rem;
}

.center {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;
}

.progress-section {
	text-align: center;
	margin-bottom: 2rem;
}

.progress-bar {
	width: 100%;
	height: 20px;
	background: #eee;
	border-radius: 10px;
	overflow: hidden;
	margin: 1rem 0;
}

.progress-fill {
	height: 100%;
	background: var(--orange);
	transition: width 0.3s ease;
}

.level-info {
	margin-top: 1rem;
	color: var(--orange);
}

.question {
	height: 100%;
	display: grid;
	grid-template-rows: auto 1fr auto;
	gap: 1rem;
	max-width: 1200px;
	margin: 0 auto;
	width: 100%;
}

.question-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	animation: fadeIn 0.3s ease-in;
}

.questionButton {
	font-size: 16rem;
	padding: 2rem 4rem;
	justify-self: center;
	min-width: 300px;
	text-align: center;
}

.options-grid {
	display: flex;
	justify-content: center;
	gap: 4rem;
	padding: 2rem;
	width: 100%;
}

.answerButton {
	font-size: 4em;
	padding: 3rem;
	flex: 1;
	max-width: 300px;
}

.option-content {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.option-letter {
	font-size: 1.2em;
	font-weight: bold;
	color: var(--orange);
	min-width: 1.5em;
}

.option-text {
	flex: 1;
	font-size: 0.9em;
}

.startButton, .restartButton {
	font-size: 2em;
	padding: 2rem;
}

.finish-message {
	text-align: center;
	color: var(--orange);
}

.explore-link {
	text-decoration: none;
}

.button-group {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
}

.practiceButton {
	font-size: 1.5em;
	padding: 1.5rem;
	background: var(--light-orange);
}

.feedback-animation {
	font-size: 2em;
	text-align: center;
	padding: 2rem;
	margin: 2rem 0;
	border-radius: 1rem;
	animation: fadeInOut 1s ease-in-out;
}

.feedback-animation.correct {
	background-color: rgba(76, 175, 80, 0.2);
	color: #2e7d32;
}

.feedback-animation.incorrect {
	background-color: rgba(244, 67, 54, 0.2);
	color: #c62828;
}

.feedback-animation .xp-gain {
	font-size: 0.8em;
	margin-top: 0.5rem;
	color: #ffc107;
	text-shadow: 0 0 5px rgba(255, 193, 7, 0.5);
	animation: sparkle 0.5s ease-in-out;
}

@keyframes fadeInOut {
	0% {
		opacity: 0;
		transform: scale(0.8);
	}
	20% {
		opacity: 1;
		transform: scale(1.1);
	}
	80% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(0.8);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes sparkle {
	0% {
		transform: scale(0.5);
		opacity: 0;
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

.stories-link {
	margin-top: 2rem;
	color: var(--orange);
	text-decoration: none;
	font-size: 1.2em;
	transition: color 0.2s;
}

.stories-link:hover {
	color: var(--dark-orange);
}

.hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}
</style>
