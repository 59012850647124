import { defineStore } from 'pinia'

const numberWords = [
	'zero', 'one', 'two', 'three', 'four', 'five',
	'six', 'seven', 'eight', 'nine'
]

export const useSettingsStore = defineStore('settings', {
	state: () => ({
		accessCode: null,
		openAIKey: localStorage.getItem('openAIKey') || ''
	}),

	getters: {
		accessCodeAsWords: (state) => {
			if (!state.accessCode) return ''
			return state.accessCode.split('').map(digit => numberWords[parseInt(digit)]).join(' ')
		}
	},

	actions: {
		generateAccessCode() {
			// Generate a random 4-digit number
			const code = Math.floor(1000 + Math.random() * 9000).toString()
			this.accessCode = code
			return code
		},

		validateAccessCode(inputCode) {
			return inputCode === this.accessCode
		},

		setOpenAIKey(key) {
			this.openAIKey = key
			localStorage.setItem('openAIKey', key)
		}
	}
})