import { createRouter, createWebHashHistory } from 'vue-router'
import Numbers from '../views/Numbers.vue'
import Abc from '../views/Abc.vue'
import Books from '../views/Books.vue'
import Book from '../views/Book.vue'
import quiz from '../views/quiz.vue'
import SettingsView from '../views/SettingsView.vue'
import StoriesView from '../views/StoriesView.vue'

const routes = [
	{
		path: '/',
		name: 'Numbers',
		component: Numbers
	},
	{
		path: '/abc',
		name: 'Abc',
		component: Abc
	},
	{
		path: '/books',
		name: 'Books',
		component: Books
	},
	{
		path: '/book/:dir/:page',
		name: 'Book',
		component: Book
	},
	{
		path: '/quiz',
		name: 'quiz',
		component: quiz
	},
	{
		path: '/stories',
		name: 'Stories',
		component: StoriesView
	},
	{
		path: '/stories/:id',
		name: 'Story',
		component: () => import('../views/StoryView.vue')
	},
	{
		path: '/settings',
		name: 'Settings',
		component: SettingsView
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
