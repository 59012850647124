<template>
	<menu>
		<router-link to="/">🔢</router-link>
		<router-link to="/abc">🔤</router-link>
		<router-link to="/books">📚</router-link>
		<router-link to="/quiz">❔</router-link>
		<router-link to="/settings" class="settings-link">⚙️</router-link>
	</menu>
	<header></header>
	<div class="container">
		<router-view/>
	</div>
	<div id="activationOverlay" @click="activatePlayer">Activate</div>
	<audio id="player"></audio>
	<div id="debug">{{ ua }}<br/></div>
</template>
<script>
export default {
	data() {
		return {
			ua: navigator.userAgent,
		}
	},
	methods: {
		activatePlayer() {
			var audio = document.getElementById("player")
			var startAudio = () => {
				var playProm = audio.play();
				playProm.then(() => {}, () => {});
				document.getElementById("activationOverlay").style.display = "none";
				audio.removeEventListener("canplaythrough", startAudio);
			}
			audio.addEventListener("canplaythrough", startAudio)
			audio.src = 'static/books/nothing.mp3'
			audio.autoplay = true;
			audio.load(); // for iOS...
		}
	},
}
</script>

<style>
:root {
	--light-orange: hsla(30, 70%, 80%, 1.0);
	--orange: hsla(30, 80%, 60%, 1.0);
	--dark-orange: hsla(30, 70%, 50%, 1.0);
	--font-color: hsla(0, 90%, 20%, 1.0);
	--shadow: hsla(30, 100%, 20%, 1.0);
	--white: hsla(0, 0%, 100%, 1.0);
	--light-grey: hsla(0, 0%, 95%, 1.0);
	--dark-grey: hsla(0, 0%, 16%, 1.0);
	--lighter: hsla(0, 0%, 100%, 0.15);
	--background: var(--white);

	--color-01: hsla(0,		100%, 34%, 1.0);
	--color-02: hsla(60,	100%, 34%, 1.0);
	--color-03: hsla(120,	100%, 34%, 1.0);
	--color-04: hsla(180,	100%, 34%, 1.0);
	--color-05: hsla(240,	100%, 34%, 1.0);
	--color-06: hsla(300,	100%, 34%, 1.0);

	--menu-size: 10vw;
	--menu-font-size: 6vw;
	--menu-gap: 1vh;
	--menu-tile-size: 15vh;
	--menu-line-height: var(--menu-tile-size);
}

@media (orientation: portrait) {
	:root {
		--menu-size: 10vh;
		--menu-font-size: 4.5vw;
		--menu-gap: 1vW;
		--menu-tile-size: 15vw;
		--menu-line-height: calc(var(--menu-size) - 2*var(--menu-gap));
	}
}

#app {
	width: auto; /* better than 100vw to avoid horizontal scrollbars */
	height: 100vh;
	display: grid;
	grid-template-columns: var(--menu-size) auto;
	grid-template-rows: 0vh auto;
	grid-template-areas:
		"menu header"
		"menu content";
}

@media (orientation: portrait) {
	#app {
		grid-template-columns: auto;
		grid-template-rows: 0vh auto var(--menu-size);
		grid-template-areas:
			"header"
			"content"
			"menu";
	}
}

#app:not(.unlocked) .confidential {
	display: none;
}

#activationOverlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: hsla(0, 0%, 20%, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 4vw;
	color: #aaa;
}

menu {
	margin: 0;
	padding: var(--menu-gap);
	grid-area: menu;
	display: flex;
	flex-direction: column;
	gap: var(--menu-gap);
	background: var(--dark-grey);
	font-variant: small-caps;
	user-select: none;
	height: 100%;
	box-sizing: border-box;
}

@media (orientation: portrait) {
	menu {
		height: auto;
		flex-direction: row;
		justify-content: center;
	}

	menu .settings-link {
		order: 1;
	}
}

menu a {
	height: var(--menu-tile-size);
	white-space: nowrap;
	line-height: var(--menu-line-height);
	text-align: center;
	font-size: var(--menu-font-size);
	color: var(--light-grey);
	text-decoration: none;
	background: var(--lighter);
	overflow-x: clip;
}

menu .settings-link {
	margin-top: auto;
}

header {
	grid-area: header;
	background: var(--light-grey);
}

.container {
	grid-area: content;
	padding: 0vh 0vw;
	background-color: var(--background);
}

#nav {
	padding: 30px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #42b983;
}

#debug {
	display: none;
	position: absolute;
	bottom: 3vh;
	left: 12vw;
	width: 30vw;
	height: 20vh;
	background: var(--white);
	font-size: 6px;
	border: 1px dashed red;
}
</style>
