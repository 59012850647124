<template>
	<div class="settings-page">
		<div v-if="!isAuthenticated" class="access-code-section">
			<h2>Settings Access</h2>
			<p>Access Code: {{ displayedAccessCodeWords }}</p>
			<div class="input-display">{{ inputCode || '____' }}</div>
			<div class="numpad">
				<button 
					v-for="n in 9" 
					:key="n"
					@click="appendNumber(n)"
					class="numpad-btn"
					tabindex="0"
				>
					{{ n }}
				</button>
				<button 
					@click="clearInput"
					class="numpad-btn"
					tabindex="0"
				>
					C
				</button>
				<button 
					@click="appendNumber(0)"
					class="numpad-btn"
					tabindex="0"
				>
					0
				</button>
				<button 
					@click="validateCode"
					class="numpad-btn enter-btn"
					tabindex="0"
				>
					↵
				</button>
			</div>
			<p v-if="message" class="message" data-test="message">{{ message }}</p>
		</div>

		<div v-else class="settings-form" data-test="settings-form">
			<h2>Settings</h2>
			<div class="form-group">
				<label for="openai-key">OpenAI API Key</label>
				<input
					type="text"
					id="openai-key"
					v-model="openAIKey"
					data-test="openai-key-input"
					class="form-input"
				/>
			</div>
			<div class="button-group">
				<button
					@click="saveSettings"
					@touchstart.prevent="saveSettings"
					data-test="save-settings"
					class="btn primary"
				>
					Save Settings
				</button>
				<button 
					@click="logout" 
					@touchstart.prevent="logout"
					class="btn secondary"
				>
					Logout
				</button>
			</div>
			<p v-if="message" class="message" data-test="message">{{ message }}</p>

			<!-- Story Management Section -->
			<div class="story-management">
				<h3>Story Management</h3>
				<div class="stories-list">
					<div v-for="story in stories" :key="story.id" class="story-item">
						<div class="story-info">
							<h4>{{ story.topic }}</h4>
							<p class="story-date">{{ formatDate(story.createdAt) }}</p>
							<p class="story-language">Language: {{ (story.language || getBrowserLanguage()).toUpperCase() }}</p>
						</div>
						<div class="story-actions">
							<button 
								@click="exportStory(story.id)"
								@touchstart.prevent="exportStory(story.id)"
								class="btn export"
								:title="'Export story about ' + story.topic"
								:data-test="'export-story-' + story.id"
							>
								💾
							</button>
							<button 
								@click="deleteStory(story.id)"
								@touchstart.prevent="deleteStory(story.id)"
								class="btn delete"
								:title="'Delete story about ' + story.topic"
							>
								🗑️
							</button>
						</div>
					</div>
					<p v-if="stories.length === 0" class="no-stories">
						No stories yet. Create some stories to see them here!
					</p>
				</div>
				<div class="import-section">
					<input
						type="file"
						accept=".json"
						@change="handleFileImport"
						ref="fileInput"
						class="hidden"
						data-test="import-story-input"
					>
					<button 
						@click="$refs.fileInput.click()"
						class="btn import"
					>
						📥 Import Story
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useSettingsStore } from '../stores/settingsStore'
import { useSpaceRepetitionStore } from '../stores/spaceRepetitionStore'

export default {
	name: 'SettingsView',
	setup() {
		const settingsStore = useSettingsStore()
		const spaceRepetitionStore = useSpaceRepetitionStore()
		const isAuthenticated = ref(false)
		const inputCode = ref('')
		const openAIKey = ref(settingsStore.openAIKey)
		const message = ref('')

		const displayedAccessCodeWords = computed(() => {
			return settingsStore.accessCodeAsWords
		})

		const stories = computed(() => spaceRepetitionStore.allStories)

		const appendNumber = (num) => {
			if (inputCode.value.length < 4) {
				inputCode.value += num.toString()
				if (inputCode.value.length === 4) {
					validateCode()
				}
			}
		}

		const clearInput = () => {
			inputCode.value = ''
		}

		const validateCode = () => {
			if (settingsStore.validateAccessCode(inputCode.value.toString())) {
				isAuthenticated.value = true
				message.value = ''
			} else {
				message.value = 'Invalid access code'
				inputCode.value = ''
			}
		}

		const saveSettings = () => {
			settingsStore.setOpenAIKey(openAIKey.value)
			message.value = 'Settings saved successfully!'
		}

		const logout = () => {
			isAuthenticated.value = false
			inputCode.value = ''
			message.value = ''
			settingsStore.generateAccessCode()
		}

		const deleteStory = async (storyId) => {
			if (confirm('Are you sure you want to delete this story and its associated quiz cards?')) {
				try {
					await spaceRepetitionStore.$patch((state) => {
						const storyIndex = state.stories.findIndex(s => s.id === storyId)
						if (storyIndex === -1) return

						const story = state.stories[storyIndex]
						
						// Clean up story audio URL if it exists
						if (story.audioUrl) {
							URL.revokeObjectURL(story.audioUrl)
						}

						// Remove the story
						state.stories.splice(storyIndex, 1)

						// Remove associated cards
						state.cards = state.cards.filter(card => card.storyId !== storyId)
					})
					spaceRepetitionStore.saveToLocalStorage()
					message.value = 'Story deleted successfully!'
				} catch (error) {
					console.error('Error deleting story:', error)
					message.value = 'Failed to delete story. Please try again.'
				}
			}
		}

		const exportStory = async (storyId) => {
			try {
				await spaceRepetitionStore.exportStory(storyId)
				message.value = 'Story exported successfully!'
			} catch (error) {
				console.error('Error exporting story:', error)
				message.value = 'Failed to export story. Please try again.'
			}
		}

		const formatDate = (date) => {
			return new Date(date).toLocaleDateString()
		}

		const getBrowserLanguage = () => {
			return navigator.language.split('-')[0] || 'en'
		}

		// Handle keyboard input for numpad
		const handleKeydown = (event) => {
			if (!isAuthenticated.value) {
				if (event.key >= '0' && event.key <= '9') {
					appendNumber(parseInt(event.key))
				} else if (event.key === 'Enter') {
					validateCode()
				} else if (event.key === 'Backspace' || event.key === 'Delete') {
					clearInput()
				}
			}
		}

		const handleFileImport = async (event) => {
			const file = event.target.files?.[0]
			if (!file) return

			try {
				const reader = new FileReader()
				reader.onload = async (e) => {
					try {
						const importData = JSON.parse(e.target.result)
						await spaceRepetitionStore.importStory(importData)
						message.value = 'Story imported successfully!'
					} catch (error) {
						console.error('Error importing story:', error)
						message.value = 'Failed to import story. Please check the file format.'
					}
				}
				reader.readAsText(file)
			} catch (error) {
				console.error('Error reading file:', error)
				message.value = 'Failed to read file. Please try again.'
			}

			// Clear the input so the same file can be selected again
			event.target.value = ''
		}

		onMounted(() => {
			settingsStore.generateAccessCode()
			window.addEventListener('keydown', handleKeydown)
		})

		return {
			isAuthenticated,
			inputCode,
			openAIKey,
			displayedAccessCodeWords,
			message,
			stories,
			validateCode,
			saveSettings,
			logout,
			deleteStory,
			exportStory,
			handleFileImport,
			formatDate,
			getBrowserLanguage,
			appendNumber,
			clearInput
		}
	},
}
</script>

<style scoped>
.settings-page {
	max-width: 800px;
	margin: 2rem auto;
	padding: 2rem;
	background: white;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.access-code-section, .settings-form {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.form-group {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.form-input {
	padding: 0.5rem;
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 1rem;
}

.input-group {
	display: flex;
	gap: 1rem;
}

.button-group {
	display: flex;
	gap: 1rem;
	margin-top: 1rem;
}

.btn {
	padding: 0.5rem 1rem;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-size: 1rem;
	transition: background-color 0.2s;
}

.btn.primary {
	background-color: #4CAF50;
	color: white;
}

.btn.secondary {
	background-color: #f5f5f5;
	color: #333;
	border: 1px solid #ddd;
}

.btn:hover {
	opacity: 0.9;
}

.story-management {
	margin-top: 2rem;
	padding-top: 2rem;
	border-top: 1px solid #eee;
}

.story-management h3 {
	color: var(--orange);
	margin-bottom: 1rem;
}

.stories-list {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.story-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	background: #f8f8f8;
	border-radius: 8px;
	transition: background-color 0.2s;
}

.story-item:hover {
	background: #f0f0f0;
}

.story-info h4 {
	margin: 0;
	color: #333;
}

.story-date {
	color: #666;
	font-size: 0.9em;
	margin: 0.2rem 0;
}

.story-language {
	color: #666;
	font-size: 0.9em;
	margin: 0;
}

.story-actions {
	display: flex;
	gap: 0.5rem;
}

.btn.delete {
	background: none;
	border: none;
	font-size: 1.2em;
	padding: 0.5rem;
	cursor: pointer;
	transition: transform 0.2s;
}

.btn.delete:hover {
	transform: scale(1.1);
}

.btn.export {
	background: none;
	border: none;
	font-size: 1.2em;
	padding: 0.5rem;
	cursor: pointer;
	transition: transform 0.2s;
}

.btn.export:hover {
	transform: scale(1.1);
}

.import-section {
	margin-top: 2rem;
	text-align: center;
}

.btn.import {
	background-color: var(--orange);
	color: white;
	display: inline-flex;
	align-items: center;
	gap: 0.5rem;
	font-size: 1.1em;
	padding: 0.75rem 1.5rem;
}

.hidden {
	display: none;
}

.no-stories {
	text-align: center;
	color: #666;
	padding: 2rem;
	background: #f8f8f8;
	border-radius: 8px;
}

.message {
	color: #666;
	margin-top: 1rem;
	text-align: center;
	padding: 0.5rem;
	border-radius: 4px;
	background: #f0f0f0;
}

.input-display {
	font-size: 2em;
	text-align: center;
	margin: 1rem 0;
	font-family: monospace;
	letter-spacing: 0.5em;
	color: var(--orange);
}

.numpad {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 0.5rem;
	max-width: 300px;
	margin: 0 auto;
}

.numpad-btn {
	padding: 1rem;
	font-size: 1.5em;
	border: 1px solid #ddd;
	border-radius: 8px;
	background: white;
	cursor: pointer;
	transition: all 0.2s;
}

.numpad-btn:hover, .numpad-btn:focus {
	background: #f0f0f0;
	border-color: var(--orange);
	outline: none;
}

.numpad-btn:active {
	transform: scale(0.95);
}

.enter-btn {
	background: var(--orange);
	color: white;
	font-size: 2em;
}

.enter-btn:hover, .enter-btn:focus {
	background: var(--orange);
	opacity: 0.9;
}
</style> 